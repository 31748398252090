import { styled } from "styled-components";

export const AboutTextContainer = styled.div`
  margin-right: 3rem;

  h2 {
    margin-bottom: 3rem;
  }

  p {
    font-size: 18px;
    line-height: 22.77px;
    color: var(--grey3);

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    b {
      color: var(--grey1);
    }
  }

  @media only screen and (max-width: 1050px) {
    margin: 0;
  }
`;

export const AboutIconContainer = styled.div`
  margin-top: 5rem;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
  }
`;
