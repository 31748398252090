import { styled } from "styled-components";

export const CheckboxCardWrapper = styled.div`
  display: flex;
  align-items: center;
  background: var(--lead);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: fit-content;

  &.selected {
    background: var(--blue2);
  }

  input {
    margin-right: 0.5rem;
  }

  label {
    color: var(--lighter);
    line-height: normal;
  }

  @media only screen and (max-width: 1120px) {
    padding: 0.25rem 0.5rem;
  }

  @media only screen and (max-width: 900px) {
    label {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 800px) {
    label {
      font-size: 12px;
    }
  }
`;
