import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --blue: #273782;
        --blue2: #3F66FB;
        --yellow: #FFC510;
        --lead: #545E75;
        --ice: #F4F5F8;
        --green: #44B827;

        --dark: #030303;
        --grey1: #3A393D;
        --grey2: #55555F;
        --grey3: #63626A;
        --grey4: #7E7E7E;
        --grey5: #3D3D3D;
        --grey6: #D9D9D9;
        --grey7: #AFAFAF;
        --grey8: #858585;
        --grey9: #424242;
        --light: #E2E0E5;
        --darker: #000000;
        --lighter: #FFFFFF;

        --hPadding: 15rem;

        @media only screen and (min-width: 2300px) {
            --hPadding: 20rem;
        }

        @media only screen and (max-width: 1600px) {
            --hPadding: 10rem;
        }

        @media only screen and (max-width: 1400px) {
            --hPadding: 5rem;
        }

        @media only screen and (max-width: 1200px) {
            --hPadding: 3rem;
        }

        @media only screen and (max-width: 500px) {
            --hPadding: 1rem;
        }
    }

    body {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    button {
        border: 0;
        cursor: pointer;
    }

    input, textarea, select {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid var(--grey6);
        border-radius: 5px;
        font-size: 16px;
        padding: 0.75rem;
        resize: none;
    }

    ::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::placeholder {
        color: var(--grey7);
    }

    h1, h2, h3, h4, a {
        margin: 0;
        font-family: 'Raleway', Sans-Serif;
        color: var(--dark);
    }

    h1, h2 {
        font-weight: 600;
    }

    h3, h4 {
        font-weight: 500;
    }

    h1 {
        font-size: 48px;
        line-height: 56.35px;
    }

    h2 {
        font-size: 40px;
        line-height: 46.96px;
    }

    h3 {
        font-size: 32px;
        line-height: 37.57px;
    }

    h4 {
        font-size: 24px;
        line-height: 28.18px;
    }

    p, span, label, button, input, textarea {
        margin: 0;
        font-family: 'Be Vietnam Pro', Sans-Serif;
    }

    .h-padded {
        padding-left: var(--hPadding);
        padding-right: var(--hPadding);
    }

    .v-padded {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .main {
        position: relative;
        top: 106px;

        @media only screen and (max-width: 300px) {
            top: 79px;
        }
    }

    .flexbox {
        display: flex;

        &.block-on-mobile {
            @media only screen and (max-width: 1050px) {
                display: block;
            }
        }
    }

    .h-spaced-between {
        justify-content: space-between;
    }

    .h-centered {
        justify-content: center;
    }

    .v-centered {
        align-items: center;
    }

    .btn {
        display: block;
        font-family: "Be Vietnam Pro", sans-serif;
        font-size: 18px;
        font-weight: 500;
        width: fit-content;
        border-radius: 10px;
        padding: 0.5rem 3rem;
        text-align: center;
        transition: all 400ms;

        &.not-block {
            display: initial;
        }

        &.square {
            border-radius: 5px;
        }

        &.sm {
            padding: 0.75rem 1rem;
        }

        &.yellow {
            background: var(--yellow);
            color: var(--grey1);

            &:hover {
                color: var(--blue2);
                filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.2));
            }
        }

        &.blue {
            background: var(--blue);
            color: var(--lighter);

            &:hover {
                color: var(--light);
                background: var(--blue2);
                filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.5));
            }
        }

        &.white {
            background: none;
            border: 1px solid var(--blue);
            color: var(--grey1);

            &:hover {
                background: var(--blue);
                color: var(--lighter);
                filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.5));
            }
        }
    }

    .gta_arrow {
        color: var(--ice);
        background: var(--grey8);
        border-radius: 4px;
        font-size: 16px;
    }
    
    .gta_pagination {
        padding-left: var(--hPadding);
        padding-right: var(--hPadding);
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 0.5rem;

        li {
            width: 100%;
        }
    }
    
    .gta_page {
        display: block;
        background: var(--grey8) !important;
        border-radius: 0;
        margin: 0;
        width: 100%;
        height: 5px;
        opacity: 0.2;
    
        &.is-active {
          transform: none;
          opacity: 1;
        }
    }

    .splide__slide img {
        @media only screen and (max-width: 1050px) {
            display: block;
            width: 100%;
        }
    }

    .swal2-container {
        font-family: 'Raleway', Sans-Serif;
    }
`;

export default GlobalStyle;
