import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

import { getCurrencyFormat } from "../../services/functions";
import { api } from "../../services/api";

const ProductCard = ({ data, isHighlight }) => {
  const [priceFull, setPriceFull] = useState();
  const [priceCurrent, setPriceCurrent] = useState();
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [productImgs, setProductImgs] = useState([]);

  useEffect(() => {
    if (!data.id) return;

    if (data.full_price) {
      setPriceFull(getCurrencyFormat(data.full_price));
    } else {
      setPriceFull();
    }
    setPriceCurrent(getCurrencyFormat(data.current_price));

    const imgs = data.media.filter(item => item.media_type === "Image");
    setProductImgs(imgs);
  }, [data]);

  const stepIndex = step => {
    const maxIndex = productImgs.length > 4 ? 3 : productImgs.length - 1;

    let newIndex = currentImgIndex + step;
    if (newIndex < 0) {
      newIndex = maxIndex;
    } else if (newIndex > maxIndex) {
      newIndex = 0;
    }
    setIndex(newIndex);
  };

  const setIndex = newIndex => {
    setCurrentImgIndex(newIndex);
  };

  return (
    data && (
      <S.ProductCardWrapper>
        <S.ProductCardImgWrapper>
          {productImgs.length > 0 ? (
            <>
              <img
                src={`${api.getUri()}/storage/${
                  productImgs[currentImgIndex].path
                }`}
                alt={data.name}
              />
              {productImgs.length > 1 && (
                <div>
                  <button
                    className="gta_arrow arrow_prev"
                    onClick={() => stepIndex(-1)}
                  >
                    <span className="material-symbols-rounded">arrow_back</span>
                  </button>
                  <button
                    className="gta_arrow arrow_next"
                    onClick={() => stepIndex(1)}
                  >
                    <span className="material-symbols-rounded">
                      arrow_forward
                    </span>
                  </button>

                  <div className="pagination">
                    {productImgs.map((img, i) => {
                      if (i <= 3) {
                        return (
                          <button
                            key={i}
                            className={`gta_page ${
                              i === currentImgIndex ? "is-active" : ""
                            }`}
                            onClick={() => setIndex(i)}
                          ></button>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="placeholder">
              <p className="large">
                <span className="material-symbols-rounded">no_photography</span>
                Nenhuma foto encontrada
              </p>
            </div>
          )}
        </S.ProductCardImgWrapper>

        <S.ProductCardTextWrapper>
          <p className="title">{data.name}</p>

          {isHighlight ? (
            <p className="description">{data.short_description}</p>
          ) : (
            <p className="dimensions">{`${data.width} x ${data.length} x ${data.height}`}</p>
          )}

          {!isHighlight && (
            <>
              {priceFull && <p className="full-price">{priceFull}</p>}
              <p className="price">{priceCurrent}</p>
            </>
          )}

          <Link
            to={`/produtos/${data.id}`}
            className={isHighlight ? "details-link" : "btn yellow square"}
          >
            {isHighlight ? "Saiba mais" : "Ver mais detalhes"}
          </Link>
        </S.ProductCardTextWrapper>

        {!isHighlight && (
          <S.ProductCardTypeWrapper>
            <p>{data.sale_type}</p>
          </S.ProductCardTypeWrapper>
        )}
      </S.ProductCardWrapper>
    )
  );
};

export default ProductCard;
