import * as S from "./styles";

const ToggleButton = ({ id, label, state, handler }) => {
  return (
    <div className="flexbox v-centered">
      <S.ToggleButton
        id={id}
        type="checkbox"
        value={state}
        checked={state}
        onChange={handler}
      />

      <S.ToggleLabel htmlFor={id}>{label}</S.ToggleLabel>
    </div>
  );
};

export default ToggleButton;
