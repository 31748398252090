import { Link, ScrollRestoration } from "react-router-dom";
import * as S from "./styles";

import Logo from "../Logo";

import { CONSTANTS } from "../../services/paths";

const Footer = () => {
  return (
    <>
      <div style={{ background: "var(--lead)" }} className="main">
        <S.FooterWrapper className="h-padded">
          <S.FooterContactWrapper>
            <Logo />

            <S.FooterLinkWrapper>
              <Link to={"/"}>Home</Link>
              <Link to={"/quem-somos"}>Quem somos</Link>
              <Link to={"/produtos"}>Produtos</Link>
              <Link to={"/orcamento"}>Orçamento</Link>
              <Link to={"https://admin.gtaguaritas.com.br"} target="_blank">
                Ferramentas para administradores
              </Link>
            </S.FooterLinkWrapper>

            <p>
              Rua Ilha Solteira, nº 10 - Esplanada, Embu das Artes, São Paulo
              CEP 06817-220
            </p>
            <h4>Telefones</h4>
            <p>{`Comercial ${CONSTANTS.phone_comercial}`}</p>
            <p>{`Financeiro ${CONSTANTS.phone_financeiro}`}</p>
            <p>{`Fixo (1º opção) ${CONSTANTS.phone_fixed_1}`}</p>
            <p>{`Fixo (2º opção) ${CONSTANTS.phone_fixed_2}`}</p>

            <S.FooterRightsWrapper>
              <p>Todos os direitos reservados @ GTA Guaritas</p>
            </S.FooterRightsWrapper>
          </S.FooterContactWrapper>
        </S.FooterWrapper>
      </div>

      <ScrollRestoration />
    </>
  );
};

export default Footer;
