import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as S from "../styles/Details";

import ProductCard from "../components/ProductCard";
import ToggleButton from "../components/ToggleButton";

import useWindowSize from "../hooks/useWindowSize";
import { useLoading } from "../contexts/LoadingContext";
import { useContactButton } from "../contexts/ContactButtonContext";

import { api } from "../services/api";
import { getCurrencyFormat, onRequestError } from "../services/functions";
import { CONSTANTS } from "../services/paths";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Details = () => {
  const [product, setProduct] = useState();
  const [priceFull, setPriceFull] = useState();
  const [priceCurrent, setPriceCurrent] = useState();
  const [selectedOptionals, setSelectedOptionals] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const windowSize = useWindowSize();
  const { setLoading } = useLoading();
  const { setShowButton } = useContactButton();
  const params = useParams();

  const slideMain = useRef();
  const slideThumbs = useRef();

  const slideOptions = {
    type: "loop",
    perPage: windowSize.width > 1050 ? 3 : 1,
    gap: 0,
    focus: 0,
    height: windowSize.width > 1050 ? "400px" : "250px",

    classes: {
      pagination: "splide__pagination gta_pagination",
      page: "splide__pagination__page gta_page",
    },
  };

  useEffect(() => {
    if (!params) return;

    const getProduct = async () => {
      await api
        .get(`/api/products/${params.id}`)
        .then(response => setProduct(response.data))
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    setLoading(true);
    getProduct();

    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (!product) return;
    // console.log("TESTE product", product);

    if (product.full_price) {
      setPriceFull(getCurrencyFormat(product.full_price));
    } else {
      setPriceFull();
    }
    setPriceCurrent(getCurrencyFormat(product.current_price));
  }, [product]);

  useEffect(() => {
    if (!slideMain.current || !slideThumbs.current) return;

    slideMain.current.sync(slideThumbs.current.splide);
  }, [slideMain, slideThumbs]);

  useEffect(() => {
    setShowButton(!showOverlay);
  }, [showOverlay, setShowButton]);

  const onToggleOptional = (isChecked, itemName) => {
    // console.log("TESTE onToggleOptional isChecked", isChecked);
    // console.log("TESTE onToggleOptional itemName", itemName);

    let copy = [...selectedOptionals];

    if (isChecked) {
      copy.push(itemName);
    } else {
      const index = copy.indexOf(itemName);
      copy.splice(index, 1);
    }

    setSelectedOptionals(copy);
  };

  const selectThumb = index => {
    setCarouselIndex(index);
  };

  const nextSlide = () => {
    let nextIndex = carouselIndex + 1;
    if (nextIndex > product.media.length - 1) {
      nextIndex = 0;
    }

    setCarouselIndex(nextIndex);
  };

  const prevSlide = () => {
    let prevIndex = carouselIndex - 1;
    if (prevIndex < 0) {
      prevIndex = product.media.length - 1;
    }

    setCarouselIndex(prevIndex);
  };

  if (!product) {
    return (
      <h2 style={{ textAlign: "center", padding: "6rem 0" }}>
        Produto não encontrado!
      </h2>
    );
  }

  return (
    <>
      {product.media.length > 0 && (
        <S.DetailsCarouselContainer>
          <Splide options={slideOptions} aria-label="fotos guarita">
            {product.media.map((media, i) => (
              <SplideSlide
                key={i}
                onClick={() => {
                  selectThumb(i);
                  setShowOverlay(true);
                }}
              >
                {media.media_type === "Image" ? (
                  <img src={`${api.getUri()}/storage/${media.path}`} alt="" />
                ) : (
                  <div>
                    <video
                      src={`${api.getUri()}/storage/${media.path}`}
                    ></video>
                    <div className="video-overlay">
                      <span className="material-symbols-rounded">
                        play_circle
                      </span>
                    </div>
                  </div>
                )}
              </SplideSlide>
            ))}
          </Splide>
        </S.DetailsCarouselContainer>
      )}

      {windowSize.width <= 1050 && (
        <S.DetailsPriceContainer>
          {priceFull && (
            <p className="price-old">
              <span>De </span>
              <span className="crossed">{priceFull}</span>
            </p>
          )}
          <p className="price-new">{priceCurrent}</p>

          {selectedOptionals.length > 0 && (
            <div>
              <p>Opcionais escolhidos</p>

              {selectedOptionals.map((item, i) => {
                return <p key={i}>{item}</p>;
              })}
            </div>
          )}

          <Link
            to="/orcamento"
            className="btn white square"
            state={{ product: product, optionals: selectedOptionals }}
          >
            Faça um orçamento
          </Link>
        </S.DetailsPriceContainer>
      )}

      <div className="h-padded v-padded">
        <div className="flexbox h-spaced-between">
          <S.DetailsTextContainer>
            <p className="breadcrumbs">
              <span>
                <Link to="/produtos">Produtos</Link>
              </span>
              <span> / </span>
              <span className="location">{product.name}</span>
            </p>
            <h2>{product.name}</h2>
            <p className="description">{product.full_description}</p>

            <h3>Contém</h3>

            <div>
              <span className="material-symbols-rounded">straighten</span>
              <div>
                <h4>Medidas</h4>
                <p className="details">{`Altura: ${product.height}m | Largura: ${product.width}m | Profundidade: ${product.length}m `}</p>
              </div>
            </div>

            <div>
              <span className="material-symbols-rounded">handyman</span>
              <div>
                <h4>Fabricação</h4>
                <p className="details">{product.build_description}</p>
              </div>
            </div>

            <div>
              <span className="material-symbols-rounded">garage_home</span>
              <div>
                <h4>Inclusos</h4>
                <p className="details">{product.inclusions_description}</p>
              </div>
            </div>
          </S.DetailsTextContainer>

          {windowSize.width > 1050 && (
            <S.DetailsPriceContainer>
              {priceFull && (
                <p className="price-old">
                  <span>De </span>
                  <span className="crossed">{priceFull}</span>
                </p>
              )}
              <p className="price-new">{priceCurrent}</p>

              {selectedOptionals.length > 0 && (
                <div>
                  <p>Opcionais escolhidos</p>

                  {selectedOptionals.map((item, i) => {
                    return <p key={i}>{item}</p>;
                  })}
                </div>
              )}

              <Link
                to="/orcamento"
                className="btn white square"
                state={{ product: product, optionals: selectedOptionals }}
              >
                Faça um orçamento
              </Link>
            </S.DetailsPriceContainer>
          )}
        </div>

        {product.optionals?.length > 0 && (
          <S.DetailsOptionalsContainer>
            <h3>Customize sua Guarita</h3>
            <p>Clique nos botões para adicionar os itens à sua guarita</p>

            {product.optionals.map((item, i) => {
              return (
                <ToggleButton
                  key={i}
                  id={`inp_opt_${item.id}`}
                  label={item.name}
                  handler={e => onToggleOptional(e.target.checked, item.name)}
                />
              );
            })}
          </S.DetailsOptionalsContainer>
        )}

        {product.similar_products.length > 0 && (
          <S.DetailsRelatedContainer>
            <h3>Produtos similares</h3>
            <div className="container">
              {product.similar_products.map((prod, i) => {
                if (i < CONSTANTS.max_related) {
                  return <ProductCard key={i} data={prod} />;
                }

                return null;
              })}
            </div>
          </S.DetailsRelatedContainer>
        )}
      </div>

      {showOverlay && (
        <S.DetailsOverlayContainer>
          <button
            type="button"
            className="btn-exit"
            onClick={() => setShowOverlay(false)}
          >
            <span className="material-symbols-rounded">close</span>
          </button>

          <div className="main-display">
            {product.media[carouselIndex].media_type === "Image" ? (
              <img
                src={`${api.getUri()}/storage/${
                  product.media[carouselIndex].path
                }`}
                alt=""
              />
            ) : (
              <video controls muted>
                <source
                  src={`${api.getUri()}/storage/${
                    product.media[carouselIndex].path
                  }`}
                />
              </video>
            )}
          </div>

          <div className="thumbs">
            <div className="carousel">
              {product.media.map((media, i) => (
                <div
                  key={i}
                  className={`${
                    i === carouselIndex ? "is-active " : ""
                  }container`}
                  onClick={() => {
                    selectThumb(i);
                  }}
                >
                  {media.media_type === "Image" ? (
                    <img src={`${api.getUri()}/storage/${media.path}`} alt="" />
                  ) : (
                    <>
                      <video
                        src={`${api.getUri()}/storage/${media.path}`}
                      ></video>
                      <div className="video-overlay">
                        <span className="material-symbols-rounded">
                          play_circle
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>

            <button type="button" className="btn-prev" onClick={prevSlide}>
              <span className="material-symbols-rounded">arrow_back</span>
            </button>
            <button type="button" className="btn-next" onClick={nextSlide}>
              <span className="material-symbols-rounded">arrow_forward</span>
            </button>
          </div>
        </S.DetailsOverlayContainer>
      )}
    </>
  );
};

export default Details;
