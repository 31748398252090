import * as S from "./styles";

import { ASSET_PATHS, CONSTANTS } from "../../services/paths";
import { formatInternationalNumber } from "../../services/functions";

const ContactButton = () => {
  return (
    <S.ContactButtonWrapper
      href={`https://wa.me/${formatInternationalNumber(
        CONSTANTS.phone_comercial
      )}`}
      target="blank"
    >
      <img src={ASSET_PATHS.logo_whatsapp} alt="" />
      <p>WhatsApp</p>
    </S.ContactButtonWrapper>
  );
};

export default ContactButton;
