import { styled } from "styled-components";

export const StyledInput = styled.input`
  -webkit-appearance: none;
  outline: none;
  background: var(--light2);
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1.5px solid var(--grey5);
  padding: 0;
  position: relative;
  transition: all 250ms;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background: var(--light2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 250ms;
  }

  &:hover {
    border: 1.5px solid var(--blue2);
  }

  &:checked {
    border: 1.5px solid var(--blue);

    &::before {
      background: var(--blue);
    }
  }
`;
