export const ASSET_PATHS = {
  logo: `${process.env.PUBLIC_URL}/assets/logos/gta.svg`,
  logo_whatsapp: `${process.env.PUBLIC_URL}/assets/logos/whatsapp.svg`,

  security: `${process.env.PUBLIC_URL}/assets/images/security.jpg`,
  worker: `${process.env.PUBLIC_URL}/assets/images/worker.png`,
  g1: `${process.env.PUBLIC_URL}/assets/images/guarita_coletiva_1.png`,
  g2: `${process.env.PUBLIC_URL}/assets/images/guarita_dupla_1.png`,
  g3: `${process.env.PUBLIC_URL}/assets/images/guarita_simples_1.png`,

  icon_environment: `${process.env.PUBLIC_URL}/assets/icons/icon_environment.png`,
  icon_jewel: `${process.env.PUBLIC_URL}/assets/icons/icon_jewel.png`,
  icon_quality: `${process.env.PUBLIC_URL}/assets/icons/icon_quality.png`,
  icon_rocket: `${process.env.PUBLIC_URL}/assets/icons/icon_rocket.png`,
  icon_staff: `${process.env.PUBLIC_URL}/assets/icons/icon_staff.png`,
  icon_tools: `${process.env.PUBLIC_URL}/assets/icons/icon_tools.png`,
};

export const CONSTANTS = {
  phone_comercial: "(11) 98787-4124",
  phone_financeiro: "(11) 98131-6356",
  phone_fixed_1: "(11) 4241-1410",
  phone_fixed_2: "(11) 4241-1419",
  max_price: 20000,
  max_highlights: 4,
  max_related: 4,
};
