import { styled } from "styled-components";

export const BannerWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  h1 {
    font-weight: 800;
    color: var(--grey1);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 16px;
    line-height: 20.24px;
    margin-bottom: 1.5rem;
  }

  img {
    max-height: 500px;
  }

  div.text-container {
    margin-right: 3rem;
  }

  a.mobile {
    margin: 3rem auto;
  }

  @media only screen and (max-width: 1050px) {
    div.text-container {
      margin: 1rem 0;
      text-align: center;
    }

    .gta_pagination {
      bottom: 5rem;
    }
  }
`;
