import React, { useEffect } from "react";
import * as S from "./styles";

import Logo from "../Logo";

import useWindowSize from "../../hooks/useWindowSize";

import { Link, useLocation } from "react-router-dom";

const toggleOverlay = () => {
  const div = document.getElementById("overlay_header");
  div.classList.toggle("is-active");
};

const MobileLink = ({ url, label, classes }) => {
  return (
    <Link to={url} onClick={toggleOverlay} className={classes ? classes : ""}>
      {label}
    </Link>
  );
};

const Header = () => {
  const location = useLocation();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!location || windowSize.width <= 1050) return;

    const path = location.pathname.split("/")[1];
    let btnId;
    switch (path) {
      case "":
        btnId = "btn_header_home";
        break;

      case "quem-somos":
        btnId = "btn_header_about";
        break;

      case "produtos":
        btnId = "btn_header_products";
        break;

      default:
        btnId = null;
        break;
    }

    let btnEl;
    document.querySelectorAll("[id^='btn_header_']").forEach(btn => {
      if (btn.id === btnId) {
        btnEl = btn;
        btn.classList.add("disabled");
      } else {
        btn.classList.remove("disabled");
      }
    });

    const selectorEl = document.getElementById("selector");
    if (btnId) {
      setTimeout(() => {
        selectorEl.style.width = `${btnEl.offsetWidth}px`;
        selectorEl.style.left = `${btnEl.offsetLeft}px`;
      }, 350);
    } else {
      selectorEl.style.width = "0";
    }
  }, [location, windowSize]);

  return (
    <S.HeaderOuterWrapper>
      <S.HeaderWrapper className="h-padded flexbox h-spaced-between v-centered">
        <div>
          <Logo />
        </div>

        <nav className="flexbox v-centered">
          {windowSize.width > 1050 && (
            <>
              <Link id="btn_header_home" to={"/"}>
                Home
              </Link>
              <Link id="btn_header_about" to={"/quem-somos"}>
                Quem somos
              </Link>
              <Link id="btn_header_products" to={"/produtos"}>
                Produtos
              </Link>
            </>
          )}
          <Link
            id="btn_budget"
            to={"/orcamento"}
            className="btn blue square sm not-block"
          >
            Orçamento
          </Link>

          {windowSize.width <= 1050 && (
            <button onClick={toggleOverlay}>
              <span className="material-symbols-rounded">menu</span>
            </button>
          )}
        </nav>
      </S.HeaderWrapper>

      <div id="selector"></div>

      {windowSize.width <= 1050 && (
        <S.HeaderOverlay id="overlay_header">
          <Logo callback={toggleOverlay} />
          <button onClick={toggleOverlay}>
            <span className="material-symbols-rounded">close</span>
          </button>
          <MobileLink url="/" label="Home" />
          <MobileLink url="/quem-somos" label="Quem somos" />
          <MobileLink url="/produtos" label="Produtos" />
          <MobileLink
            url="/orcamento"
            label="Fazer orçamento"
            classes="btn blue square sm"
          />
        </S.HeaderOverlay>
      )}
    </S.HeaderOuterWrapper>
  );
};

export default Header;
