import { styled } from "styled-components";

export const FooterWrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--lighter);
`;

export const FooterContactWrapper = styled.div`
  max-width: 35%;

  img {
    margin-bottom: 1rem;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  h4 {
    font-size: 16px;
    line-height: 18.78px;
    font-weight: 700;
    margin: 3rem 0 1rem;
    color: var(--lighter);
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;

    img {
      margin-bottom: 3rem;
    }
  }
`;

export const FooterRightsWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: var(--hPadding);

  p {
    font-size: 14px;
    line-height: 17.71px;
  }

  @media only screen and (max-width: 900px) {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 3rem 0;
  }
`;

export const FooterLinkWrapper = styled.nav`
  display: flex;
  position: absolute;
  top: 3rem;
  right: var(--hPadding);

  a {
    font-size: 16px;
    font-weight: 700;
    line-height: 18.78px;
    color: var(--lighter);
    transition: all 350ms;

    &:not(:last-child) {
      margin-right: 2.5rem;
    }

    &:hover {
      color: var(--grey7);
    }
  }

  @media only screen and (max-width: 900px) {
    display: block;
    position: relative;
    top: unset;
    right: unset;

    a {
      display: block;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      &:last-child {
        margin-bottom: 3rem;
      }
    }
  }
`;
