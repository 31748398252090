import { styled } from "styled-components";

export const FilterListWrapper = styled.div`
  padding: 0.5rem 0 1.5rem !important;
  color: var(--darker);

  p {
    margin-bottom: 1.5rem;
  }

  input {
    margin-right: 1rem;
  }

  label {
    width: 100%;
  }

  div.option:not(:last-of-type) {
    margin-bottom: 0.75rem;
  }
`;
