import { styled } from "styled-components";

export const HeaderOuterWrapper = styled.div`
  background: var(--yellow);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.14);
  position: fixed;
  width: 100vw;
  z-index: 10;

  div#selector {
    background: var(--lead);
    position: absolute;
    bottom: 0;
    height: 5px;
  }
`;

export const HeaderWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;

  a {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 18px;
    line-height: 22.77px;
    position: relative;
    transition: all 350ms;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    &:hover {
      color: var(--blue2);
    }

    &.disabled {
      color: var(--blue);
      pointer-events: none;
    }
  }

  button {
    background: none;

    span {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 1050px) {
    a {
      margin-right: 1.5rem !important;
    }
  }

  @media only screen and (max-width: 360px) {
    img {
      max-width: 80px;
    }

    a {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 300px) {
    img {
      max-width: 60px;
    }
  }
`;

export const HeaderOverlay = styled.div`
  background: var(--lighter);
  position: absolute;
  top: 0;
  left: 100vw;
  width: calc(100vw - var(--hPadding) * 2);
  height: 100vh;
  z-index: 1;
  padding: 1rem var(--hPadding);
  transition: all 400ms;

  img {
    margin-bottom: 3rem;
  }

  a {
    display: block;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;

    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }

    &.btn {
      width: auto;
    }
  }

  button {
    background: none;
    position: absolute;
    top: 2rem;
    right: 1rem;

    span {
      color: var(--grey1);
      font-size: 28px;
    }
  }

  &.is-active {
    left: 0;
  }
`;
