import { styled } from "styled-components";

export const IconBoxWrapper = styled.div`
  border: solid 1px var(--blue);
  border-radius: 10px;
  width: 18%;
  padding: 1rem;

  p {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: var(--grey2);
  }

  img {
    width: 20%;
    display: block;
    margin: 1rem auto 1.5rem;
  }

  @media only screen and (max-width: 1050px) {
    width: 65%;
    margin: 0 auto 2rem;
  }
`;
