import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as S from "../styles/Contact";

import CheckboxCard from "../components/CheckboxCard";

import useWindowSize from "../hooks/useWindowSize";
import { useLoading } from "../contexts/LoadingContext";

import { api } from "../services/api";
import { compareAsString, onRequestError } from "../services/functions";
import { CONSTANTS } from "../services/paths";

import InputMask from "react-input-mask";

const Contact = () => {
  const [products, setProducts] = useState([]);
  const [saleType, setSaleType] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOptionals, setSelectedOptionals] = useState([]);
  const [emailSent, setEmailSent] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [company, setCompany] = useState("");
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState("");
  const [msg, setMsg] = useState("");

  const windowSize = useWindowSize();
  const { setLoading } = useLoading();
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setSaleType(location.state.product.sale_type);
      setSelectedProductId(location.state.product.id);
      setSelectedOptionals(location.state.optionals);
    } else {
      setSaleType("Venda");
    }
  }, [location]);

  useEffect(() => {
    if (products.length === 0) return;

    if (!selectedProductId) {
      setSelectedProduct(null);
    } else {
      setSelectedProduct(
        products.find(prod => compareAsString(prod.id, selectedProductId))
      );
    }
  }, [products, selectedProductId]);

  useEffect(() => {
    if (!saleType) return;

    const getProducts = async () => {
      setLoading(true);
      await api
        .get(`/api/products?saleTypes=${saleType}`)
        .then(response => setProducts(response.data))
        .catch(e => {
          if (!e.caught) {
            e.msg = "Não foi possível encontrar produtos";
          }
          onRequestError(e.msg, e);
        })
        .finally(() => setLoading(false));
    };

    getProducts();
    // eslint-disable-next-line
  }, [saleType]);

  const handleEmail = async e => {
    e.preventDefault();

    const data = {
      name: name,
      email: email,
      phone: phone,
      cnpj: cnpj,
      company_name: company,
      cep: cep,
      address: address,
      msg: msg,
      sale_type: saleType,
      product: selectedProduct,
      optionals: selectedOptionals,
    };

    setLoading(true);
    await api
      .post("/api/request-estimate", data)
      .then(() => setEmailSent(true))
      .catch(e => {
        if (!e.caught) {
          e.msg = "Houve um problema ao enviar o orçamento!";
        }
        onRequestError(e.msg, e);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="flexbox h-padded v-padded block-on-mobile">
        <S.ContactTextContainer>
          <h2>Solicite um orçamento!</h2>
          <p style={{ lineHeight: "26px", marginBottom: "1rem" }}>
            Assim que recebermos seu contato, retornaremos o mais rápido
            possível. Preencha todas as informações do formulário para solicitar
            um orçamento com mais precisão.
          </p>
          <p style={{ marginBottom: "4rem" }}>Obrigado!</p>

          <div>
            <span className="material-symbols-outlined">phone_in_talk</span>
            <div>
              <p className="contact">{`Comercial ${CONSTANTS.phone_comercial}`}</p>
              <p className="contact">{`Financeiro ${CONSTANTS.phone_financeiro}`}</p>
            </div>
          </div>

          <div>
            <span className="material-symbols-outlined">mail</span>
            <div>
              <p className="contact">comercial@gtaguaritas.com.br</p>
              <p className="contact">financeiro@gtaguaritas.com.br</p>
            </div>
          </div>

          <div>
            <span className="material-symbols-outlined">schedule</span>
            <div>
              <h4>Horário de funcionamento</h4>
              <p className="info">Segunda a quinta das 7 às 17hr</p>
              <p className="info">Sexta das 7 às 16hr</p>
            </div>
          </div>

          <div>
            <span className="material-symbols-outlined">pin_drop</span>
            <div>
              <h4>Endereço</h4>
              <p className="info">Rua Ilha Solteira, nº 10 - Esplanada</p>
              <p className="info">Embu das Artes - SP</p>
              <p className="info">CEP: 06817-220</p>
            </div>
          </div>
        </S.ContactTextContainer>

        {windowSize.width > 1050 && (
          <S.ContactFormContainer onSubmit={handleEmail}>
            {emailSent ? (
              <div
                className="flexbox v-centered h-centered"
                style={{
                  height: "100%",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <h3 style={{ marginBottom: "2rem" }}>
                  Seu orçamento foi enviado com sucesso!
                </h3>
                <h4>Aguarde nosso contato.</h4>
              </div>
            ) : (
              <>
                <div>
                  <label htmlFor="inp_name">Nome</label>
                  <input
                    id="inp_name"
                    type="text"
                    placeholder="Digite seu nome"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="inp_email">E-mail</label>
                  <input
                    id="inp_email"
                    type="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="inp_phone">Telefone</label>
                  <InputMask
                    id="inp_phone"
                    type="tel"
                    mask="(99) 99999-9999"
                    placeholder="(00) 00000-0000"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="inp_cnpj">CNPJ</label>
                  <InputMask
                    id="inp_cnpj"
                    type="text"
                    mask="9999.9999.9999-99"
                    placeholder="0000.0000.0000-00"
                    value={cnpj}
                    onChange={e => setCnpj(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label htmlFor="inp_company">Nome da empresa</label>
                  <input
                    id="inp_company"
                    type="text"
                    placeholder="Digite o nome de sua empresa"
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                    required
                  />
                </div>

                <div className="flexbox">
                  <div>
                    <label htmlFor="inp_cep">CEP</label>
                    <InputMask
                      id="inp_cep"
                      type="text"
                      mask="99999-999"
                      placeholder="00000-000"
                      value={cep}
                      onChange={e => setCep(e.target.value)}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="inp_address">
                      Endereço de entrega (Cidade, Rua, Número)
                    </label>
                    <input
                      id="inp_address"
                      type="text"
                      placeholder="Digite o endereço de entrega"
                      value={address}
                      onChange={e => setAddress(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="flexbox">
                  <div>
                    <label htmlFor="inp_model">Modelo</label>
                    <select
                      id="inp_model"
                      value={saleType}
                      onChange={e => {
                        setSelectedProductId("");
                        setSelectedOptionals([]);
                        setSaleType(e.target.value);
                      }}
                    >
                      <option value={"Venda"}>Venda</option>
                      <option value={"Locação"}>Locação</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="inp_box">Escolha sua Guarita</label>
                    <select
                      id="inp_box"
                      value={selectedProductId}
                      onChange={e => {
                        setSelectedOptionals([]);
                        setSelectedProductId(e.target.value);
                      }}
                      required
                    >
                      <option value={""}>Escolha um produto</option>
                      {products.map((prod, i) => (
                        <option key={i} value={prod.id}>
                          {`${prod.name} ${prod.width} x ${prod.length}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {selectedProduct?.optionals.length > 0 && (
                  <div>
                    <label>Opcionais</label>
                    <S.CheckboxWrapper>
                      {selectedProduct.optionals.map((opt, i) => (
                        <CheckboxCard
                          key={i}
                          id={opt.id}
                          name={opt.name}
                          nameArrayState={selectedOptionals}
                          nameArrayHandler={setSelectedOptionals}
                        />
                      ))}
                    </S.CheckboxWrapper>
                  </div>
                )}

                <div>
                  <label htmlFor="inp_message">Mais detalhes</label>
                  <textarea
                    id="inp_message"
                    rows={4}
                    placeholder="Digite sua mensagem"
                    value={msg}
                    onChange={e => setMsg(e.target.value)}
                  />
                </div>

                <button type="submit">Solicitar Orçamento</button>
              </>
            )}
          </S.ContactFormContainer>
        )}
      </div>

      {windowSize.width <= 1050 && (
        <S.ContactFormContainer onSubmit={handleEmail}>
          {emailSent ? (
            <div
              className="flexbox v-centered h-centered"
              style={{
                height: "100%",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <h3 style={{ marginBottom: "2rem" }}>
                Seu orçamento foi enviado com sucesso!
              </h3>
              <h4>Aguarde nosso contato.</h4>
            </div>
          ) : (
            <>
              <div>
                <label htmlFor="inp_name">Nome</label>
                <input
                  id="inp_name"
                  type="text"
                  placeholder="Digite seu nome"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_email">E-mail</label>
                <input
                  id="inp_email"
                  type="email"
                  placeholder="Digite seu e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_phone">Telefone</label>
                <InputMask
                  id="inp_phone"
                  type="tel"
                  mask="(99) 99999-9999"
                  placeholder="(00) 00000-0000"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_cnpj">CNPJ</label>
                <InputMask
                  id="inp_cnpj"
                  type="text"
                  mask="9999.9999.9999-99"
                  placeholder="0000.0000.0000-00"
                  value={cnpj}
                  onChange={e => setCnpj(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_company">Nome da empresa</label>
                <input
                  id="inp_company"
                  type="text"
                  placeholder="Digite o nome de sua empresa"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_cep">CEP</label>
                <InputMask
                  id="inp_cep"
                  type="text"
                  mask="99999-999"
                  placeholder="00000-000"
                  value={cep}
                  onChange={e => setCep(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_address">
                  Endereço de entrega (Cidade, Rua, Número)
                </label>
                <input
                  id="inp_address"
                  type="text"
                  placeholder="Digite o endereço de entrega"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="inp_model">Modelo</label>
                <select
                  id="inp_model"
                  value={saleType}
                  onChange={e => {
                    setSelectedProductId("");
                    setSelectedOptionals([]);
                    setSaleType(e.target.value);
                  }}
                >
                  <option value={"Venda"}>Venda</option>
                  <option value={"Locação"}>Locação</option>
                </select>
              </div>

              <div>
                <label htmlFor="inp_box">Escolha sua Guarita</label>
                <select
                  id="inp_box"
                  value={selectedProductId}
                  onChange={e => {
                    setSelectedOptionals([]);
                    setSelectedProductId(e.target.value);
                  }}
                  required
                >
                  <option value={""}>Escolha um produto</option>
                  {products.map((prod, i) => (
                    <option key={i} value={prod.id}>
                      {`${prod.name} ${prod.width} x ${prod.length}`}
                    </option>
                  ))}
                </select>
              </div>

              {selectedProduct?.optionals.length > 0 && (
                <div>
                  <label>Opcionais</label>
                  <S.CheckboxWrapper>
                    {selectedProduct.optionals.map((opt, i) => (
                      <CheckboxCard
                        key={i}
                        id={opt.id}
                        name={opt.name}
                        nameArrayState={selectedOptionals}
                        nameArrayHandler={setSelectedOptionals}
                      />
                    ))}
                  </S.CheckboxWrapper>
                </div>
              )}

              <div>
                <label htmlFor="inp_message">Mais detalhes</label>
                <textarea
                  id="inp_message"
                  rows={4}
                  placeholder="Digite sua mensagem"
                  value={msg}
                  onChange={e => setMsg(e.target.value)}
                />
              </div>

              <button type="submit">Solicitar Orçamento</button>
            </>
          )}
        </S.ContactFormContainer>
      )}
    </>
  );
};

export default Contact;
