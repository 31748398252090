import * as S from "./styles";

import { ASSET_PATHS } from "../../services/paths";

import { useNavigate } from "react-router-dom";

const Logo = ({ callback }) => {
  const navigate = useNavigate();

  return (
    <S.LogoWrapper
      src={ASSET_PATHS.logo}
      alt="Logo GTA Guaritas"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate("/");

        if (callback) {
          callback();
        }
      }}
    />
  );
};

export default Logo;
