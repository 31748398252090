import { styled } from "styled-components";

export const DetailsTextContainer = styled.div`
  width: 100%;
  max-width: 60%;
  margin-right: 3rem;

  h2,
  h3 {
    margin-bottom: 2rem;
  }

  h2,
  h3,
  h4 {
    color: var(--darker);
  }

  h3 {
    font-size: 24px;
    line-height: 28.18px;
    font-weight: 500;
  }

  h4 {
    font-family: "Be Vietnam Pro", sans-serif;
    font-size: 18px;
    line-height: 22.77px;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 18px;
    line-height: 26px;

    &.breadcrumbs {
      color: var(--grey8);
      margin-bottom: 1rem;

      & a {
        color: var(--grey8);
        text-decoration: underline;

        &:hover {
          color: var(--dark);
        }
      }
    }

    &.description {
      color: var(--grey2);
      margin-bottom: 3rem;
    }

    &.details {
      font-size: 16px;
      line-height: 20.24px;
      color: var(--grey2);
    }
  }

  & > div {
    display: flex;

    span {
      margin-right: 0.75rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const DetailsPriceContainer = styled.div`
  background: var(--ice);
  border: 1px solid var(--blue);
  border-radius: 10px;
  padding: 2rem;
  height: fit-content;

  p {
    &.price-old {
      font-size: 16px;
      line-height: 20.24px;
      font-weight: 500;
      color: var(--grey8);
      margin-bottom: 0.5rem;
    }

    &.price-new {
      font-size: 36px;
      line-height: 45.54px;
      font-weight: 600;
      color: var(--darker);
      margin-bottom: 2rem;
    }

    .crossed {
      text-decoration: line-through;
    }
  }

  a {
    width: auto;
  }

  div {
    margin-bottom: 3rem;

    p {
      font-size: 14px;
      line-height: 17.71px;
      color: var(--grey4);

      &:first-of-type {
        font-size: 16px;
        line-height: 20.24px;
        font-weight: 500;
        color: var(--grey2);
      }

      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    border: none;
    border-radius: 0;
    padding: 2rem 1rem;
    width: auto;
  }
`;

export const DetailsOptionalsContainer = styled.div`
  border-top: 1px solid var(--grey8);
  margin-top: 2rem;
  padding-top: 2rem;

  p {
    font-size: 18px;
    line-height: 26px;
    color: var(--grey4);
    margin: 1.5rem 0 2rem;
  }

  div {
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    span {
      font-size: 18px;
      line-height: 26px;
      color: var(--grey1);
      margin-left: 0.5rem;
    }
  }
`;

export const DetailsCarouselContainer = styled.div`
  background: var(--ice);

  img,
  video {
    width: 100%;
    height: 400px;
    margin: 0 auto;
    object-fit: cover;
  }

  .gta_pagination {
    display: flex !important;
    flex-wrap: nowrap;
    bottom: -0.5rem;
    padding: 0 !important;

    li {
      width: 100%;

      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }
  }

  .splide__slide {
    cursor: pointer;
  }

  div.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 84px;
      color: var(--blue2);
      background: var(--lighter);
      border-radius: 50%;
    }
  }

  @media only screen and (max-width: 1050px) {
    img {
      width: auto;
      height: 250px;
    }

    .gta_pagination {
      padding: 0 var(--hPadding) 0 var(--hPadding) !important;
    }
  }
`;

export const DetailsOverlayContainer = styled.div`
  background: var(--grey9);
  position: fixed;
  width: calc(100vw - (var(--hPadding) * 2));
  height: calc(100vh - (3rem * 2));
  top: 0;
  left: 0;
  z-index: 100;
  padding: 3rem var(--hPadding);

  button.btn-exit {
    background: none;
    color: var(--lighter);
  }

  div.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 32px;
      color: var(--blue2);
      background: var(--lighter);
      border-radius: 50%;
    }
  }

  div.main-display {
    width: 100%;
    margin: 2rem 0;

    img,
    video {
      max-width: 100%;
      max-height: 600px;
      margin: 0 auto;
      display: block;
    }
  }

  div.thumbs {
    button {
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--grey8);
      color: var(--lighter);
      border-radius: 5px;
      padding: 0.25rem;

      &.btn-prev {
        left: var(--hPadding);
        transform: translateY(-50%);
      }

      &.btn-next {
        right: var(--hPadding);
        transform: translate(-50%, -50%);
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .carousel {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 30px;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .container {
      position: relative;
      width: 150px;
      height: 88px;
      opacity: 0.6;
      cursor: pointer;

      &.is-active {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    div.thumbs .carousel {
      display: none;
    }
  }
`;

export const DetailsRelatedContainer = styled.div`
  padding: 6rem 0;

  h3 {
    margin-bottom: 3rem;
  }

  & > div.container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 1050px) {
    & > div.container {
      gap: 4rem;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 600px) {
    & > div.container {
      gap: 2rem;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
