const NotFound = () => {
  return (
    <div className="h-padded v-padded" style={{ textAlign: "center" }}>
      <h1 style={{ marginBottom: "3rem" }}>OOPS!</h1>
      <h2 style={{ marginBottom: "3rem" }}>
        Não encontramos a página que você deseja.
        <br />
        <br />
        Use os links para voltar à navegação.
      </h2>
    </div>
  );
};

export default NotFound;
