import { styled } from "styled-components";

export const ToggleButton = styled.input`
  -webkit-appearance: none;
  outline: none;
  background: var(--grey6);
  width: 36px;
  height: 22px;
  border-radius: 50px;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--grey4);
    position: absolute;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    transition: 0.15s;
  }

  &:checked {
    background: var(--yellow);

    &::before {
      left: 18px;
      background: var(--blue);
    }
  }
`;

export const ToggleLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0;
  margin-left: 0.5rem;
`;
