import * as S from "./styles";

const IconBox = ({ imgSrc, text }) => {
  return (
    <S.IconBoxWrapper>
      <img src={imgSrc} alt="" />
      <p>{text}</p>
    </S.IconBoxWrapper>
  );
};

export default IconBox;
