import Banner from "../Banner";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const BannerCarousel = ({ slides }) => {
  const slideOptions = {
    type: "fade",
    rewind: true,
    autoplay: true,

    classes: {
      pagination: "splide__pagination gta_pagination",
      page: "splide__pagination__page gta_page",
    },
  };

  return (
    <div style={{ background: "var(--ice)", minHeight: "350px" }}>
      {slides.length > 0 && (
        <Splide options={slideOptions} aria-label="banners guaritas">
          {slides.map((slide, i) => (
            <SplideSlide key={i}>
              <Banner data={slide} />
            </SplideSlide>
          ))}

          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev gta_arrow">
              <span className="material-symbols-rounded">arrow_back</span>
            </button>
            <button className="splide__arrow splide__arrow--next gta_arrow">
              <span className="material-symbols-rounded">arrow_forward</span>
            </button>
          </div>
        </Splide>
      )}
    </div>
  );
};

export default BannerCarousel;
