import { styled } from "styled-components";

export const HomeContainer = styled.div`
  h2 {
    text-align: center;
    margin-bottom: 3rem;
  }

  a.products-btn {
    margin: 3rem auto 0;
  }
`;

export const HomeIconContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 25%;

  img {
    margin-right: 1rem;
  }

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22.77px;
    color: var(--lead);
  }

  @media only screen and (max-width: 1050px) {
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

export const HomeProductsContainer = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: 1600px) {
    gap: 3rem;
  }

  @media only screen and (max-width: 1300px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 1050px) {
    gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 600px) {
    gap: 2rem;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const HomeQuoteContainer = styled.div`
  display: flex;
  margin-top: 8rem;

  div.text-container {
    margin-right: 3rem;
  }

  h2 {
    font-size: 64px;
    font-weight: 800;
    line-height: 90px;
    text-align: left;

    & > span {
      font-family: "Raleway", sans-serif;
      background: var(--yellow);
      padding: 0.25rem;
    }
  }

  p {
    font-size: 22px;
    line-height: 30px;
    color: var(--grey1);

    &.quote {
      font-family: "Raleway", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23.48px;
      color: var(--lighter);
      background: rgba(61, 61, 61, 0.9);
      text-align: center;
      border-radius: 5px;
      padding: 1rem 2.5rem;
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      width: 65%;

      &::before,
      &::after {
        font-size: 64px;
        line-height: 64px;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
      }

      &::before {
        content: open-quote;
        top: 0.5rem;
        left: 1.5rem;
      }

      &::after {
        content: close-quote;
        bottom: -1.5rem;
        right: 2rem;
      }
    }
  }

  @media only screen and (max-width: 1050px) {
    display: block;
    text-align: center;

    h2 {
      text-align: center;
    }

    div.text-container {
      margin: 0 0 5rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  @media only screen and (max-width: 400px) {
    h2 {
      font-size: 50px;
      line-height: 80px;
    }
  }

  @media only screen and (max-width: 300px) {
    h2 {
      font-size: 42px;
      line-height: 60px;
    }
  }
`;
