import React, { createContext, useContext, useState } from "react";

const initialState = {
  loading: false,
};

export const LoadingContext = createContext(initialState);

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(initialState.loading);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  return useContext(LoadingContext);
}
