import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

import useWindowSize from "../../hooks/useWindowSize";

import { api } from "../../services/api";

const Banner = ({ data }) => {
  const [linkUrl, setLinkUrl] = useState();
  const [linkLabel, setLinkLabel] = useState();

  const windowSize = useWindowSize();

  useEffect(() => {
    if (!data.name) return;

    if (data.link_label) {
      setLinkLabel(data.link_label);
    } else {
      setLinkLabel("Ver Guarita");
    }

    if (data.product_id) {
      setLinkUrl(`/produtos/${data.product_id}`);
    } else if (data.link_url) {
      setLinkUrl(data.link_url);
    }
  }, [data]);

  return (
    data && (
      <S.BannerWrapper className="flexbox h-padded h-spaced-between v-centered block-on-mobile">
        <div className="text-container">
          <h1>{data.name}</h1>
          <p>{data.text}</p>
          {windowSize.width > 1050 && linkUrl && (
            <Link to={linkUrl} className="btn yellow square">
              {linkLabel}
            </Link>
          )}
        </div>

        {data.image_path && (
          <div>
            <img src={`${api.getUri()}/storage/${data.image_path}`} alt={""} />
          </div>
        )}

        {windowSize.width <= 1050 && linkUrl && (
          <Link to={linkUrl} className="btn yellow square mobile">
            {linkLabel}
          </Link>
        )}
      </S.BannerWrapper>
    )
  );
};

export default Banner;
