import Swal from "sweetalert2";

export const getCurrencyFormat = num => {
  // Retorna uma string com formato de moeda a partir de um número
  const value = typeof num === "number" ? num : parseFloat(num);
  return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};

export const compareAsString = (val1, val2, lowercase = false) => {
  // Retorna a comparação de dois valores convertidos em string
  let str1 = val1.toString();
  let str2 = val2.toString();
  if (lowercase) {
    str1.toLowerCase();
    str2.toLowerCase();
  }

  return str1 === str2;
};

export const fireSuccessMsg = (msg, callback) => {
  // Dispara uma mensagem de sucesso para o usuário
  Swal.fire({
    text: msg,
    icon: "success",
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 3000,
  }).then(() => {
    if (callback) {
      callback();
    }
  });
};

export const onRequestError = (msg, error, callback) => {
  // Dispara uma mensagem de erro para o usuário e loga o erro original
  Swal.fire({
    text: msg,
    icon: "error",
    title: "Oops...",
    position: "top-end",
    showConfirmButton: false,
    timerProgressBar: true,
    timer: 3000,
  }).then(() => {
    if (callback) {
      callback();
    }
  });

  console.error(error);
};

export const formatInternationalNumber = telNumber => {
  let formatted = "55";
  formatted += telNumber.replace(/[() -]/gm, "");
  return formatted;
};
