import * as S from "./styles";

import StyledCheckbox from "../StyledCheckbox";

const FilterList = ({
  category,
  optionsList,
  selectedList,
  selectedListHandler,
}) => {
  const addToList = value => {
    if (selectedList.includes(value)) return;

    const copy = [...selectedList, value];
    selectedListHandler(copy);
  };

  const removeFromList = value => {
    if (!selectedList.includes(value)) return;

    const copy = selectedList.filter(items => items !== value);
    selectedListHandler(copy);
  };

  return (
    <S.FilterListWrapper>
      <p>{category}</p>
      <div>
        {optionsList.map((opt, i) => {
          let inputId, labelName;
          if (opt.id) {
            inputId = `inp_${category}_${opt.id}`;
            labelName = opt.name;
          } else {
            inputId = `inp_${category}_${opt}`;
            labelName = opt;
          }

          return (
            <div key={i} className="flexbox v-centered option">
              <StyledCheckbox
                id={inputId}
                handler={e => {
                  const value = opt.id ? opt.id : opt;
                  if (e.target.checked) {
                    addToList(value);
                  } else {
                    removeFromList(value);
                  }
                }}
              />
              <label htmlFor={inputId}>{labelName}</label>
            </div>
          );
        })}
      </div>
    </S.FilterListWrapper>
  );
};

export default FilterList;
