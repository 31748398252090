import { styled } from "styled-components";

export const RangeInputWrapper = styled.div`
  padding: 1.5rem 0 2.5rem !important;

  #range-slider-black {
    height: 6px;

    input {
      padding: 0;
      opacity: 0;
    }

    .range-slider__thumb {
      background: var(--dark);
      transition: transform 0.3s;
    }

    .range-slider__range {
      background: var(--grey8);
    }
  }
`;

export const ValuesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 14px;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  input {
    font-size: 14px;
  }

  & > div:first-of-type {
    margin-right: 1.5rem;
  }

  @media only screen and (max-width: 1350px) {
    font-size: 12px;

    input {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 1110px) {
    & > div:first-of-type {
      margin-right: 0.5rem;
    }
  }
`;
