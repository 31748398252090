import { useEffect, useState } from "react";
import * as S from "./styles";

import StyledCheckbox from "../StyledCheckbox";

const CheckboxCard = ({ id, name, nameArrayState, nameArrayHandler }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!name) return;
    setSelected(nameArrayState.includes(name));
  }, [name, nameArrayState]);

  const addToNameArray = name => {
    const arrayCopy = [...nameArrayState, name];
    nameArrayHandler(arrayCopy);
  };

  const removeFromNameArray = name => {
    const arrayCopy = [...nameArrayState];
    const index = arrayCopy.indexOf(name);
    arrayCopy.splice(index, 1);
    nameArrayHandler(arrayCopy);
  };

  const changeHandler = e => {
    const el = e.target;
    const check = el.checked;
    const name = el.value;

    if (check) {
      addToNameArray(name);
    } else {
      removeFromNameArray(name);
    }
  };

  const clickHandler = e => {
    const el = e.target;
    if (el.tagName === "DIV") {
      el.children[0].click();
    }
  };

  return (
    <S.CheckboxCardWrapper
      className={selected ? "selected" : ""}
      onClick={clickHandler}
    >
      <StyledCheckbox
        id={`inp_${name}_${id}`}
        state={name}
        checkCondition={selected}
        handler={changeHandler}
      />
      <label htmlFor={`inp_${name}_${id}`}>{name}</label>
    </S.CheckboxCardWrapper>
  );
};

export default CheckboxCard;
