import { styled } from "styled-components";

export const ContactButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 100;

  padding: 1rem 0.75rem;
  font-size: 18px;
  border-radius: 10px;
  background: var(--green);
  color: var(--lighter);
  filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.5));

  img {
    max-width: 50px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
  }

  @media only screen and (max-width: 1400px) {
    font-size: 14px;

    img {
      max-width: 25px;
    }
  }

  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 300px) {
    right: 1rem;
    bottom: 1rem;
    font-size: 10px;

    img {
      max-width: 20px;
    }
  }
`;
