import * as S from "./styles";

import { CONSTANTS } from "../../services/paths";

import CurrencyInput from "react-currency-input-field";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

const RangeInput = ({
  minState,
  minHandler,
  maxState,
  maxHandler,
  applyHandler,
}) => {
  const handleRange = pair => {
    minHandler(pair[0]);
    maxHandler(pair[1]);
  };

  return (
    <S.RangeInputWrapper>
      <S.ValuesWrapper>
        <div>
          <label htmlFor="inp_min_price">Preço mínimo</label>
          <CurrencyInput
            id="inp_min_price"
            placeholder="R$ 0,00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            allowNegativeValue={false}
            maxLength={5}
            value={minState}
            onValueChange={value => minHandler(value)}
            onBlur={applyHandler}
          />
        </div>

        <div>
          <label htmlFor="inp_max_price">Preço máximo</label>
          <CurrencyInput
            id="inp_max_price"
            placeholder="R$ 0,00"
            intlConfig={{ locale: "pt-BR", currency: "BRL" }}
            allowNegativeValue={false}
            maxLength={5}
            value={maxState}
            onValueChange={value => maxHandler(value)}
            onBlur={applyHandler}
          />
        </div>
      </S.ValuesWrapper>

      <RangeSlider
        id="range-slider-black"
        max={CONSTANTS.max_price}
        value={[minState, maxState]}
        onInput={handleRange}
        onThumbDragEnd={applyHandler}
        rangeSlideDisabled
      />
    </S.RangeInputWrapper>
  );
};

export default RangeInput;
