import { styled } from "styled-components";

export const ContactTextContainer = styled.div`
  margin-right: 3rem;
  flex: 1;

  h2 {
    margin-bottom: 2.5rem;
  }

  h4 {
    font-family: "Be Vietnam Pro";
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
  }

  p {
    font-size: 18px;
    line-height: 30px;
    color: var(--grey1);

    &.contact {
      font-weight: 500;
      color: var(--grey5);
    }

    &.info {
      color: var(--grey4);
    }
  }

  span {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    margin-right: 2rem;
    font-size: 36px;
    color: var(--blue);
  }

  & > div {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-right: 1.5rem;

    span {
      margin-right: 1rem;
    }

    p {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1050px) {
    margin: 0;
  }

  @media only screen and (max-width: 300px) {
    p {
      font-size: 12px;
    }

    span {
      font-size: 24px;
    }

    h4 {
      font-size: 16px;
    }
  }
`;

export const ContactFormContainer = styled.form`
  background: var(--ice);
  border-radius: 10px;
  padding: 2rem;
  flex: 2;

  label {
    font-size: 16px;
    line-height: 40px;
    color: var(--darker);
    display: block;
  }

  button {
    background: var(--yellow);
    color: var(--grey1);
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    padding: 0.75rem 0;
    margin-top: 1rem;
    transition: all 400ms;

    &:hover {
      color: var(--blue);
      filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.6));
    }
  }

  & > div {
    margin-bottom: 2rem;
  }

  div.flexbox > div {
    width: 100%;

    &:first-child {
      margin-right: 2rem;
      width: 50%;
    }
  }

  @media only screen and (max-width: 1200px) {
    div.flexbox > div:first-child {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    border-radius: 0;
    padding: 2rem 1rem 3rem;
  }

  @media only screen and (max-width: 300px) {
    input,
    label {
      font-size: 14px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  background: var(--lighter);
  padding: 1.5rem 1rem;
  margin-top: 0.5rem;
  max-height: 15rem;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  border: 1px solid var(--dark);
  display: flex;
  align-items: center;
  gap: 1rem 0.5rem;
  flex-wrap: wrap;

  @media only screen and (max-width: 1120px) {
    padding: 0.5rem;
  }
`;
