import React, { createContext, useContext, useState } from "react";

const initialState = {
  showButton: true,
};

export const ContactButtonContext = createContext(initialState);

export function ContactButtonProvider({ children }) {
  const [showButton, setShowButton] = useState(initialState.showButton);

  return (
    <ContactButtonContext.Provider
      value={{
        showButton,
        setShowButton,
      }}
    >
      {children}
    </ContactButtonContext.Provider>
  );
}

export function useContactButton() {
  return useContext(ContactButtonContext);
}
