import { styled } from "styled-components";

export const ProductsContainer = styled.div`
  h2 {
    font-weight: 700;
    color: var(--dark);
    margin-bottom: 4rem;
  }

  p.large {
    font-size: 18px;
    font-weight: 500;
  }

  button.btn-filter {
    background: none;
    border: 1px solid var(--grey7);
    border-radius: 3px;
    color: var(--grey4);
    padding: 0.25rem;

    .material-symbols-rounded {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 1050px) {
    h2 {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 400px) {
    h2 {
      font-size: 24px;
    }
  }
`;

export const ProductsCardsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);

  @media only screen and (max-width: 1800px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ProductsFiltersContainer = styled.div`
  width: 25%;
  margin-right: 3rem;

  button {
    font-size: 16px;
    text-decoration: underline;
    background: none;
    color: var(--grey3);
  }

  p {
    font-size: 16px;
    color: var(--dark);
  }

  h3 {
    color: var(--darker);
    font-weight: 600;
  }

  & > div {
    &:not(:last-of-type, .no-border) {
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid var(--grey6);
    }

    &.no-border {
      margin-bottom: 3rem;
    }
  }

  &.mobile {
    position: fixed;
    background: var(--lighter);
    z-index: 10;
    top: 0;
    left: 100vw;
    width: calc(100vw - var(--hPadding) * 2);
    height: calc(100% - 4rem);
    padding: 2rem var(--hPadding);
    margin-right: 0;
    overflow-y: scroll;
    transition: all 300ms;

    &.active {
      left: 0;
    }

    .material-symbols-rounded {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 1050px) {
    &:not(.mobile) {
      display: none;
    }
  }
`;
