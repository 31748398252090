import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({ width: null, height: null });

  useEffect(() => {
    const handleResize = () => {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setWindowSize(size);
    };

    handleResize();

    const listener = window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
