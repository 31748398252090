import { styled } from "styled-components";

export const ProductCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    .btn {
      padding: 0.5rem 1rem;
      margin: 0 auto;
    }
  }
`;

export const ProductCardImgWrapper = styled.div`
  position: relative;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--light);
  background: var(--ice);

  img {
    display: block;
    max-width: 220px;
    height: calc(205px - 2rem);
    margin: auto;
    padding: 1rem 0;
  }

  div.placeholder {
    height: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 140px;
    margin: auto;

    span {
      display: block;
      color: var(--lead);
      font-size: 36px;
      margin-bottom: 0.5rem;
    }
  }

  .gta_arrow {
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    span {
      display: block;
    }

    &.arrow_prev {
      left: 0.5rem;
    }

    &.arrow_next {
      right: 0.5rem;
    }
  }

  .pagination {
    display: flex;
    max-width: 50%;
    margin: auto;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

export const ProductCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--light);
  padding: 1.5rem;

  p {
    &.title {
      font-size: 20px;
      font-weight: 500;
      line-height: 25.3px;
      color: var(--dark);
      margin-bottom: 0.5rem;
    }

    &.description {
      font-size: 14px;
      line-height: 17.71px;
      color: var(--grey3);
      margin: 0.5rem 0 1.5rem;
    }

    &.dimensions {
      font-size: 14px;
      line-height: 17.77px;
      color: var(--grey4);
      margin-bottom: 5rem;
    }

    &.full-price {
      font-size: 14px;
      font-weight: 500;
      line-height: 17.77px;
      color: var(--grey4);
      text-decoration: line-through;
      margin-bottom: 0.25rem;
    }

    &.price {
      font-size: 22px;
      font-weight: 600;
      line-height: 27.83px;
      color: var(--grey1);
      margin: auto 0 1rem;
    }
  }

  a.details-link {
    margin-top: auto;
    font-size: 16px;
    text-decoration: underline;
    font-family: "Be Vietnam Pro", sans-serif;
    transition: all 350ms;

    &:hover {
      color: var(--blue2);
    }
  }

  div.description-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`;

export const ProductCardTypeWrapper = styled.div`
  background: rgba(39, 55, 130, 0.8);
  color: var(--lighter);
  font-size: 14px;
  line-height: 17.77px;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: fit-content;
  position: absolute;
  top: 1rem;
  right: 0.75rem;
`;
