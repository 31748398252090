import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import { LoadingProvider } from "./contexts/LoadingContext";
import { ContactButtonProvider } from "./contexts/ContactButtonContext";

import RouterApp from "./routes";
import GlobalStyle from "./styles/global";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <LoadingProvider>
      <ContactButtonProvider>
        <RouterProvider router={RouterApp} />
      </ContactButtonProvider>
    </LoadingProvider>
  </React.StrictMode>
);
