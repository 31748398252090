import * as S from "../styles/About";

import { ASSET_PATHS } from "../services/paths";
import IconBox from "../components/IconBox";

const About = () => {
  return (
    <div className="h-padded v-padded">
      <div className="flexbox block-on-mobile">
        <S.AboutTextContainer>
          <h2>Quem somos</h2>
          <p>
            A GTA GUARITAS é uma empresa brasileira, responsável pela locação e
            venda de guaritas e outros equipamentos, atuando no setor de obras
            em geral, estacionamentos , condomínios e no setor de eventos. Temos
            o diferencial em apoio logístico e alto índice de qualidade nas
            soluções e prazo de execução dos serviços.
          </p>
          <p>
            <b>Foco no Cliente:</b> Nos comprometemos a atender suas demandas no
            local e prazo necessários;
          </p>
          <p>
            <b>Segurança:</b> Somos rigorosos no cumprimento dos nossos padrões,
            prezando pela segurança dos nossos clientes e colaboradores;
          </p>
          <p>
            <b>Resultados com Qualidade:</b> Buscamos maximizar os resultados,
            valorizando a qualidade em cada detalhe de nossa operação;
          </p>
          <p>
            <b>Trabalho em Equipe:</b> Juntos alcançamos nossos objetivos,
            confiamos uns nos outros, compartilhando conquistas e resultados;
          </p>
        </S.AboutTextContainer>

        <img
          src={ASSET_PATHS.security}
          alt="Imagem de um segurança"
          style={{ display: "block", width: "100%", borderRadius: "5px" }}
        />
      </div>

      <S.AboutIconContainer>
        <h2>Por que escolher nossa empresa?</h2>
        <div className="flexbox v-centered h-spaced-between block-on-mobile">
          <IconBox
            imgSrc={ASSET_PATHS.icon_rocket}
            text={"Agilidade e eficiência no serviço realizado"}
          />
          <IconBox
            imgSrc={ASSET_PATHS.icon_staff}
            text={"Equipe altamente qualificada no que faz"}
          />
          <IconBox
            imgSrc={ASSET_PATHS.icon_tools}
            text={"Soluções de acordo com a sua necessidade"}
          />
          <IconBox
            imgSrc={ASSET_PATHS.icon_quality}
            text={"Qualidade comprovada por nossos clientes"}
          />
        </div>
      </S.AboutIconContainer>
    </div>
  );
};

export default About;
