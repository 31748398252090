import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as S from "../styles/Products";

import BannerCarousel from "../components/BannerCarousel";
import ProductCard from "../components/ProductCard";
import RangeInput from "../components/RangeInput";
import FilterList from "../components/FilterList";

import { useLoading } from "../contexts/LoadingContext";
import useWindowSize from "../hooks/useWindowSize";

import { api } from "../services/api";
import { onRequestError } from "../services/functions";
import { CONSTANTS } from "../services/paths";

const Products = () => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(CONSTANTS.max_price);
  const [slides, setSlides] = useState([]);
  const [modelTypes, setModelTypes] = useState([]);
  const [optionals, setOptionals] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedModelTypes, setSelectedModelTypes] = useState([]);
  const [selectedOptionals, setSelectedOptionals] = useState([]);
  const [selectedSaleTypes, setSelectedSaleTypes] = useState([]);
  const [order, setOrder] = useState("name,asc");

  const didInit = useRef(false);

  const { loading, setLoading } = useLoading();
  const windowSize = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const saleTypes = ["Venda", "Locação"];

  useEffect(() => {
    if (didInit.current) return;

    const getApiResources = async () => {
      const apiCalls = [
        api.get("/api/model-types"),
        api.get("/api/optionals"),
        api.post("/api/carousel-slides-page", { page: "/produtos" }),
      ];
      await Promise.all(apiCalls)
        .then(responses => {
          setModelTypes(responses[0].data);
          setOptionals(responses[1].data);
          setSlides(responses[2].data);
        })
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    didInit.current = true;
    setLoading(true);
    getApiResources();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      if (!loading) setLoading(true);

      let route = `/api/products`;
      if (searchParams.size > 0) {
        route += `?${searchParams.toString()}`;
      }

      await api
        .get(route)
        .then(response => setProducts(response.data))
        .catch(e => onRequestError(e.msg, e))
        .finally(() => setLoading(false));
    };

    getProducts();

    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (selectedModelTypes.length === 0) {
      searchParams.delete("models");
    } else {
      const str = selectedModelTypes.toString();
      searchParams.set("models", str);
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    // eslint-disable-next-line
  }, [selectedModelTypes]);

  useEffect(() => {
    if (selectedOptionals.length === 0) {
      searchParams.delete("optionals");
    } else {
      const str = selectedOptionals.toString();
      searchParams.set("optionals", str);
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    // eslint-disable-next-line
  }, [selectedOptionals]);

  useEffect(() => {
    if (!order) {
      searchParams.delete("orderBy");
    } else {
      searchParams.set("orderBy", order);
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    // eslint-disable-next-line
  }, [order]);

  useEffect(() => {
    if (selectedSaleTypes.length === 0) {
      searchParams.delete("saleTypes");
    } else {
      const str = selectedSaleTypes.toString().toLowerCase();
      searchParams.set("saleTypes", str);
    }

    setSearchParams(searchParams, { preventScrollReset: true });
    // eslint-disable-next-line
  }, [selectedSaleTypes]);

  const applyPriceFilters = () => {
    const min = parseInt(minPrice);
    const max = parseInt(maxPrice);

    if (min === 0) {
      searchParams.delete("minPrice");
    } else {
      searchParams.set("minPrice", min);
    }

    if (max === CONSTANTS.max_price) {
      searchParams.delete("maxPrice");
    } else {
      searchParams.set("maxPrice", max);
    }

    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const clearFilters = () => {
    setMinPrice(0);
    setMaxPrice(CONSTANTS.max_price);
    document
      .querySelectorAll("input:checked")
      .forEach(el => (el.checked = false));

    searchParams.delete("models");
    searchParams.delete("optionals");
    searchParams.delete("saleTypes");
    searchParams.delete("minPrice");
    searchParams.delete("maxPrice");
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  return (
    <S.ProductsContainer>
      {slides.length > 0 && <BannerCarousel slides={slides} />}

      <div className="h-padded v-padded">
        {windowSize.width > 1050 ? (
          <h2>Produtos Selecionados</h2>
        ) : (
          <div
            className="flexbox v-centered h-spaced-between"
            style={{ marginBottom: "4rem" }}
          >
            <h2>Produtos Selecionados</h2>
            <button
              className="btn-filter"
              onClick={() => {
                const el = document.getElementById("filters-overlay");
                if (el) {
                  el.classList.add("active");
                }
              }}
            >
              <span className="material-symbols-rounded">filter_alt</span>
            </button>
          </div>
        )}

        <div className="flexbox h-spaced-between">
          {windowSize.width > 1050 && (
            <S.ProductsFiltersContainer>
              <div className="flexbox h-spaced-between v-centered">
                <p className="large">Ordena por</p>
              </div>

              <div className="order">
                <select
                  id="inp_order"
                  name="order"
                  value={order}
                  onChange={e => setOrder(e.target.value)}
                >
                  <option value={"name,asc"}>Nome A-Z</option>
                  <option value={"name,desc"}>Nome Z-A</option>
                  <option value={"current_price,asc"}>Menor preço</option>
                  <option value={"current_price,desc"}>Maior preço</option>
                  <option value={"width,asc"}>Menor largura</option>
                  <option value={"width,desc"}>Maior largura</option>
                  <option value={"height,asc"}>Menor altura</option>
                  <option value={"height,desc"}>Maior altura</option>
                  <option value={"length,asc"}>Menor comprimento</option>
                  <option value={"length,desc"}>Maior comprimento</option>
                </select>
              </div>

              <div className="flexbox h-spaced-between v-centered">
                <p className="large">Filtra por</p>
                <button type="button" onClick={clearFilters}>
                  Limpar
                </button>
              </div>

              <RangeInput
                minState={minPrice}
                minHandler={setMinPrice}
                maxState={maxPrice}
                maxHandler={setMaxPrice}
                applyHandler={applyPriceFilters}
              />

              {modelTypes.length > 0 && (
                <FilterList
                  category={"Modelos"}
                  optionsList={modelTypes}
                  selectedList={selectedModelTypes}
                  selectedListHandler={setSelectedModelTypes}
                />
              )}
              {saleTypes.length > 0 && (
                <FilterList
                  category={"Tipo"}
                  optionsList={saleTypes}
                  selectedList={selectedSaleTypes}
                  selectedListHandler={setSelectedSaleTypes}
                />
              )}
              {optionals.length > 0 && (
                <FilterList
                  category={"Opcionais"}
                  optionsList={optionals}
                  selectedList={selectedOptionals}
                  selectedListHandler={setSelectedOptionals}
                />
              )}
            </S.ProductsFiltersContainer>
          )}

          <S.ProductsCardsContainer>
            {products.length > 0 ? (
              products.map((prod, i) => <ProductCard key={i} data={prod} />)
            ) : (
              <p className="large">Nenhum produto foi encontrado.</p>
            )}
          </S.ProductsCardsContainer>
        </div>
      </div>

      {windowSize.width <= 1050 && (
        <S.ProductsFiltersContainer id="filters-overlay" className="mobile">
          <div className="no-border flexbox h-spaced-between v-centered">
            <h3>Filtros de produtos</h3>
            <button
              onClick={() => {
                const el = document.getElementById("filters-overlay");
                if (el) {
                  el.classList.remove("active");
                }
              }}
            >
              <span className="material-symbols-rounded">close</span>
            </button>
          </div>

          <div className="flexbox h-spaced-between v-centered">
            <p className="large">Ordena por</p>
          </div>

          <div className="order">
            <select
              id="inp_order"
              name="order"
              value={order}
              onChange={e => setOrder(e.target.value)}
            >
              <option value={"name,asc"}>Nome A-Z</option>
              <option value={"name,desc"}>Nome Z-A</option>
              <option value={"current_price,asc"}>Menor preço</option>
              <option value={"current_price,desc"}>Maior preço</option>
              <option value={"width,asc"}>Menor largura</option>
              <option value={"width,desc"}>Maior largura</option>
              <option value={"height,asc"}>Menor altura</option>
              <option value={"height,desc"}>Maior altura</option>
              <option value={"length,asc"}>Menor comprimento</option>
              <option value={"length,desc"}>Maior comprimento</option>
            </select>
          </div>

          <div className="flexbox h-spaced-between v-centered">
            <p className="large">Filtra por</p>
            <button type="button" onClick={clearFilters}>
              Limpar
            </button>
          </div>

          <RangeInput
            minState={minPrice}
            minHandler={setMinPrice}
            maxState={maxPrice}
            maxHandler={setMaxPrice}
            applyHandler={applyPriceFilters}
          />

          {modelTypes.length > 0 && (
            <FilterList
              category={"Modelos"}
              optionsList={modelTypes}
              selectedList={selectedModelTypes}
              selectedListHandler={setSelectedModelTypes}
            />
          )}
          {saleTypes.length > 0 && (
            <FilterList
              category={"Tipo"}
              optionsList={saleTypes}
              selectedList={selectedSaleTypes}
              selectedListHandler={setSelectedSaleTypes}
            />
          )}
          {optionals.length > 0 && (
            <FilterList
              category={"Opcionais"}
              optionsList={optionals}
              selectedList={selectedOptionals}
              selectedListHandler={setSelectedOptionals}
            />
          )}
        </S.ProductsFiltersContainer>
      )}
    </S.ProductsContainer>
  );
};

export default Products;
