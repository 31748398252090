import React from "react";
import { createBrowserRouter } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Products from "./pages/Products";
import Details from "./pages/Details";
import NotFound from "./pages/404";

const RouterApp = createBrowserRouter([
  {
    path: "",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/quem-somos",
        element: <About />,
      },
      {
        path: "/orcamento",
        element: <Contact />,
      },
      {
        path: "/produtos",
        element: <Products />,
      },
      {
        path: "/produtos/:id",
        element: <Details />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default RouterApp;
