import React, { useEffect, useRef, useState } from "react";
import * as S from "../styles/Home";
import { Link } from "react-router-dom";

import { useLoading } from "../contexts/LoadingContext";

import ProductCard from "../components/ProductCard";
import BannerCarousel from "../components/BannerCarousel";

import { ASSET_PATHS, CONSTANTS } from "../services/paths";
import { api } from "../services/api";

const Home = () => {
  const [slides, setSlides] = useState([]);
  const [highlights, setHighlights] = useState([]);

  const didInit = useRef(false);

  const { setLoading } = useLoading();

  useEffect(() => {
    if (didInit.current) return;

    const getApiResources = async () => {
      const apiCalls = [
        api.post("/api/carousel-slides-page", { page: "/" }),
        api.get("/api/highlights"),
      ];
      await Promise.all(apiCalls)
        .then(responses => {
          setSlides(responses[0].data);
          setHighlights(responses[1].data);
        })
        .catch(e => console.error(e))
        .finally(() => setLoading(false));
    };

    didInit.current = true;
    setLoading(true);
    getApiResources();

    // eslint-disable-next-line
  }, []);

  return (
    <S.HomeContainer>
      {slides.length > 0 && <BannerCarousel slides={slides} />}

      <div className="h-padded v-padded">
        <div style={{ marginBottom: "8rem" }}>
          <h2>Por que comprar nossas guaritas?</h2>
          <div className="flexbox h-spaced-between block-on-mobile">
            <S.HomeIconContainer>
              <img src={ASSET_PATHS.icon_jewel} alt="" />
              <p>Matéria-prima de alta qualidade</p>
            </S.HomeIconContainer>
            <S.HomeIconContainer>
              <img src={ASSET_PATHS.icon_environment} alt="" />
              <p>Respeito com o meio-ambiente</p>
            </S.HomeIconContainer>
            <S.HomeIconContainer>
              <img src={ASSET_PATHS.icon_tools} alt="" />
              <p>Totalmente de acordo com a sua necessidade</p>
            </S.HomeIconContainer>
          </div>
        </div>

        {highlights.length > 0 && (
          <div>
            <h2>Melhores custos-benefícios</h2>
            <S.HomeProductsContainer>
              {highlights.map((item, i) => {
                if (i < CONSTANTS.max_highlights) {
                  return <ProductCard key={i} data={item} isHighlight={true} />;
                }

                return null;
              })}
            </S.HomeProductsContainer>
            <Link to="/produtos" className="btn blue products-btn">
              Ver mais produtos
            </Link>
          </div>
        )}

        <S.HomeQuoteContainer>
          <div className="text-container">
            <h2>
              Vantagens de ter a GTA como seu <span>parceiro</span>
            </h2>
            <p>
              Atuamos no setor de obras em geral, estacionamentos, condomínios e
              no setor de eventos para garantir conforto e segurança no seu
              local de trabalho.
            </p>
          </div>

          <div style={{ position: "relative" }}>
            <img src={ASSET_PATHS.worker} alt="Foto de um trabalhador" />
            <p className="quote">
              Com as Guaritas da GTA, meu trabalho ficou muito mais confortável.
            </p>
          </div>
        </S.HomeQuoteContainer>
      </div>
    </S.HomeContainer>
  );
};

export default Home;
