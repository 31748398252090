import * as S from "./styles";

const StyledCheckbox = ({ id, state, handler, checkCondition, style }) => {
  return (
    <div>
      <S.StyledInput
        id={id}
        type="checkbox"
        value={state}
        checked={checkCondition}
        onChange={handler}
        style={style}
      />
    </div>
  );
};

export default StyledCheckbox;
