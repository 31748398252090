import { Outlet } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import ContactButton from "../components/ContactButton";

import { useLoading } from "../contexts/LoadingContext";
import { useContactButton } from "../contexts/ContactButtonContext";

const MainLayout = () => {
  const { loading } = useLoading();
  const { showButton } = useContactButton();

  return (
    <>
      <Header />
      <div className="main">
        <Outlet />
        {loading && <Loading />}
      </div>
      <Footer />
      {showButton && <ContactButton />}
    </>
  );
};

export default MainLayout;
